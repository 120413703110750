import React, {useState, useEffect} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {TermsAndConditionsOfUseLink} from '../utils/constants';

const CookiesDialog = () => {
  const [open, setOpen] = useState(false);

  // Function for verif if a cookie exist
  const getCookie = (cookieName: String) => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${cookieName}=`)) {
        return cookie.split('=')[1];
      }
    }

    return null;
  };

  const handleAccept = () => {
    document.cookie =
      'cookiesAccepted=true; path=/; max-age=' + 60 * 60 * 24 * 30;
    setOpen(false);
  };

  const handleReject = () => {
    document.cookie =
      'cookiesAccepted=false; path=/; max-age=' + 60 * 60 * 24 * 30;
    setOpen(false);
  };

  useEffect(() => {
    const isCookieSet = getCookie('cookiesAccepted');
    if (!isCookieSet) {
      setOpen(true);
    }
  }, []);

  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#ffffff',
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
        p: 2,
        zIndex: 1300,
        borderTop: '1px solid #e0e0e0',
      }}
    >
      {/* Content Container */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* TITLE AND TEXT */}
        <Box>
          <Typography color="black" variant="h6" gutterBottom>
            Cookie Preferences
          </Typography>

          <Typography color="black" variant="body2">
            We use cookies on our website to give you the most relevant
            experience by remembering your preferences and repeat visits. By
            clicking &apos;Accept&apos;, you consent to use ALL the cookies.
          </Typography>
        </Box>

        {/* ACTIONS */}
        <Box sx={{display: 'flex', gap: 2, ml: 'auto'}}>
          {/* LINK TO TERMS AND CONDITIONS OF USE */}
          <a
            href={`${TermsAndConditionsOfUseLink}`}
            target={'_blank'}
            rel="noreferrer"
            color={'black'}
            style={{marginTop: '8px', color: 'black'}}
          >
            {' '}
            <span> Terms and conditions of use </span>{' '}
          </a>
          {/* ACCEPT ALL COOKIES BUTTON */}
          <Button
            onClick={handleAccept}
            variant="contained"
            disableElevation
            sx={{
              color: '#fff',
              backgroundColor: '#00A86B',
              '&:hover': {
                backgroundColor: '#008B5A',
              },
            }}
          >
            Accept
          </Button>
          {/* REJECT ALL COOKIES BUTTON */}
          <Button
            onClick={handleReject}
            variant="contained"
            disableElevation
            sx={{
              color: '#fff',
              backgroundColor: '#003366',
              '&:hover': {
                backgroundColor: '#002244',
              },
            }}
          >
            Reject All
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CookiesDialog;
